import React, { Component } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { snakeCase } from 'change-case'

import { Button, theme } from 'UI'
import { area } from 'components/withNavigation'

import BoxesItem from 'components/Boxes/BoxesItem'

const BoxesContainer = styled.div`
  transition: ${theme.transition};
  margin-left: 5vw;
`

const ItemComponent = ({ component, ...props }) => {
  if (component) {
    const Component = component
    return <Component {...props} />
  }
  return <BoxesItem {...props} />
}

@inject('pageStore')
@observer
class Boxes extends Component {
  
  componentDidMount() {
    document.withNavigation.forceRefresh()
  }
  
  render() {
    const { pageStore, component, ...rest } = this.props
    return (
      <BoxesContainer>
        {pageStore.children.map((item, index) => (
          <ItemComponent
            component={component}
            className='boxItem'
            name={`Box_${snakeCase(item.title)}`}
            key={`box_${item.href}_${index}`}
            pageColor={pageStore.color}
            focusOrder={index}
            first
            {...rest} {...item}
          />
        ))}
      </BoxesContainer>
    )
  }
}

export default area(Boxes, { oneDimension: true })
