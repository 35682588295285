import React from 'react'
import styled, { keyframes } from 'styled-components'

import theme from 'UI/theme'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const anim = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -120px;
  }
`

const Wrapper = styled.div`
  text-align: center;
`

const Spin = styled.div`
  display: inline-block;
  animation: ${rotate} 1.4s linear infinite;
  width: 40px;
  height: 40px;
  
  svg {
    width: 40px;
    height: 40px;
    circle {
      animation: ${anim} 1.4s ease-in-out infinite;
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0px;
      stroke: lightsalmon;
    }
  }
`

const Spinner = ({ className }) => {
  return (
    <Wrapper className={className || ''}>
      <Spin>
        <svg viewBox='22 22 44 44'>
          <circle cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6"></circle>
        </svg>
      </Spin>
    </Wrapper>
  )
}

export default Spinner
