import React, { Component } from 'react'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router'

import Spinner from 'UI/Spinner'
import EmptyPage from 'components/EmptyPage'

import { getStoreFor } from 'utils/storeHelpers'

const StyledMain = styled.div`
  flex: 0 0 72vw;
  width: 62vw;
  position: relative;
`

const Loading = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

@inject('pageStore', 'messagesStore', 'contactsStore', 'photosStore', 'contentStore', 'newrowStore')
@observer
class StatusHandler extends Component {
  render() {
    const { pageStore, contactsStore, messagesStore, children, match, location, ...props } = this.props
    const { name, isLoading, isEmpty, emptyMessage } = getStoreFor(location.pathname)
    return (
      <StyledMain {...props}>
        {isLoading && <Loading><Spinner /></Loading>}
        {children}
        {!isLoading && isEmpty && <EmptyPage message={emptyMessage} />}
      </StyledMain>
    )
  }
}


export default withRouter(StatusHandler)
