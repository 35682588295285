import React from 'react'
import styled from 'styled-components'

import { Img } from 'UI'

const StyledAvatar = styled(Img)`
  border-radius: 100vw;
  width: 5vw;
  height: 5vw;
`

const Avatar = props => <StyledAvatar {...props} width='5vw' height='5vw' />
export default Avatar
