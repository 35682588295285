import React, { Component } from 'react'
import styled from 'styled-components'

import { theme } from 'UI'

const StyledBlock = styled.div`
  transition: ${theme.transition};
  background: white;
  border-radius: ${theme.borderRadius};
  position: relative;
  box-shadow: ${theme.boxShadow};
`

class Block extends Component {
  render() {
    return <StyledBlock {...this.props} />
  }
}

export default Block
