import styled, { css, keyframes } from 'styled-components'

const pulse = (min = 1.1, max = 1.15) => keyframes`
  0% {
    transform: scale(${min});
  }
  50% {
    transform: scale(${max});
  }
  100% {
    transform: scale(${min});
  }
`
const pulseCss = (min, max) => css`
  ${pulse(min, max)} 1.2s ease-in-out 300ms infinite;
`

const theme = {
  number: 2,
  interval(...args) {
    const result = (args.length ? [...args] : [1]).reduce((str, curr) => `${str} ${this.number * curr}vh`, '')
    return result
  },
  get padding() { return this.interval(2) },
  get borderRadius() { return this.interval(0.5) },
  boxShadow: '0 5px 20px 1px rgba(0,0,0,0.2)',
  boxShadowSelected: '0 6px 21px 2px rgba(0,0,0,0.5)',
  get fontHeading1() { return `${this.number * 3.5}vh` },// TODO Remove smth
  get fontHeading2() { return `${this.number * 3}vh` },
  get fontHeading3() { return `${this.number * 2}vh` },
  get fontHeading4() { return `${this.number * 1.5}vh` },
  get fontText() { return `${this.number}vh` },
  font: {
    get fontHeading1() { return `${theme.number * 3.5}vh` },
    get fontHeading2() { return `${theme.number * 3}vh` },
    get fontHeading3() { return `${theme.number * 2}vh` },
    get fontHeading4() { return `${theme.number * 1.5}vh` },
    get fontText() { return `${theme.number}vh` },
  },
  colors: {
    base: '#333344',
    red: '#c13636',
    redRgba(a = 1) { return `rgba(193,54,54,${a})` },
    green: '#2f8256',
    greenRGBA(a = 1) {return `rgba(47,130,86,${a})` },
    orange: 'rgb(255,127,80)'
  },
  css: {
    transition: 'all 300ms ease-in-out',
    centered: 'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);'
  },
  transition: 'all 300ms ease-in-out',
  pulse(min, max) { return pulseCss(min, max)}
}
export default theme
