import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Block, Icon, Img, Text, theme } from 'UI'
import format from 'date-fns/format'

import config from 'utils/config'
import { parseISO } from "date-fns"

const pulse = keyframes`
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1.0);
  }
`
const animation = css`
  animation: ${pulse} 1.2s ease-in-out infinite;
`

const Thumb = styled(Img)`
  width: 14vw;
  border-radius: ${theme.borderRadius};
`

const Texts = styled.div`
  padding: 0 ${theme.interval()};
  flex: 1;
`

const Header = styled(Text.H2)`
  word-break: break-word;
`

const IconWrap = styled(Block)`
  flex: 0 0 5vw;
  height: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.interval()};
  ${p => p.selected && animation}
`

const PulseIcon = styled(Icon)`
  font-size: 4vw;
`

const Starts = styled(Text.H2)`
  margin-top: ${theme.interval()};
`

function EventListItem({ content_newrow_present, content_category, content_thumbnail, selected, content_header, content_markup, content_youtube_id, component, content_price, content_start_date, ...rest }) {
  const starts = content_start_date ? format(parseISO(content_start_date), config.dateTimeFormat) : ''

  return (
    <>
      <Thumb src={content_thumbnail ? content_thumbnail : `https://i.ytimg.com/vi/${content_youtube_id}/mqdefault.jpg`} key={`thumb_${content_youtube_id}`} />
      <Texts key={`texts${content_youtube_id}`}>
        <Header>{content_header}</Header>
        <Starts thin>{starts}</Starts>
        <Text.H2 thin>{content_price}</Text.H2>
      </Texts>
      <IconWrap selected={selected} key={`icon_${content_youtube_id}`}><PulseIcon icon={(content_newrow_present || content_category) ? 'video' : 'eye'} /></IconWrap>
    </>
  )
}

export default EventListItem
