import React, { useEffect } from 'react'
import { observable } from 'mobx'
import { observer, inject } from 'mobx-react'

import { area } from 'components/withNavigation'

function ContentDetails({ children, contentStore, pageStore, ...rest }) {
  useEffect(() => {
    pageStore.setTitle(contentStore.currentContent.content_header)
    return () => { pageStore.setTitle(undefined) }
  }, [contentStore.currentContent.content_header])
  return (
    <>{children}</>
  )
}

export default inject('contentStore', 'pageStore')(observer(area(ContentDetails)))
