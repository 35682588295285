import React from 'react'
import styled from 'styled-components'

import { theme } from 'UI'

const ButtonsRowContainer = styled.div`
  display: flex;
  ${p => p.marginTop && `margin-top: ${theme.interval(2)};`}
  > .button {
    margin: ${theme.interval(0, 1)};
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const ButtonsRow = ({ ...rest }) => <ButtonsRowContainer {...rest} />

export default ButtonsRow
