import React, { useRef, useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { theme, ButtonsRow, Button } from 'UI'

const ScrollableTextContainer = styled.div`

`

const ButtonsMoreLess = styled(ButtonsRow)`
  margin-top: ${theme.interval()};
  justify-content: space-evenly;
`

const ScrollerContainer = styled.div`
  overflow: hidden;
  max-height: 40vh;
`

const Scroller = styled.div`
  transition: ${theme.transition};
  transform: translateY(-${p => p.height * p.needScrollFor/1.7}px);
  word-break: break-all !important;
`

const notSelectedDelete = css`
  background: ${theme.colors.redRgba(0.6)};
`

const selectedDelete = css`
  background: ${theme.colors.redRgba(0.9)};
`

const stylesDelete = {
  notSelected: notSelectedDelete,
  selected: selectedDelete
}

function ScrollableText(props) {

  const { children, ...rest } = props

  const container = useRef(null)
  const scroller = useRef(null)

  const [heights, setHeight] = useState({ scroller: 0, container: 0 })

  useEffect(() => {
    setHeight({
      scroller: scroller.current.offsetHeight,
      container: container.current.offsetHeight
    })
  }, [container.current, scroller.current])

  const [ init, setInit ] = useState(0)
  const [ left, setLeft ] = useState(0)

  useEffect(() => {
    if ( heights.scroller > heights.container) {
      const howMany = Math.ceil(heights.scroller / heights.container)
      setInit(howMany)
      setLeft(howMany)
    }
  }, [heights])
  
  const increase = () => {
      setLeft(prev => prev < init ? prev + 1 : prev)
  }
  
  const decrease = () => {
    setLeft(prev => prev > 1 ? prev - 1 : prev)
  }

  const isLong = init > 1
  return (
    <ScrollableTextContainer {...rest} >
      <ScrollerContainer ref={container}>
        <Scroller ref={scroller} height={heights.container} needScrollFor={init - left}>
          {children}
        </Scroller>
      </ScrollerContainer>
      <ButtonsMoreLess>
        {props.onDeletePress &&
          <Button text='Delete' icon='trash' styles={stylesDelete} onPress={props.onDeletePress} />
        }
        {isLong &&
            <Button icon='caret-square-up' onPress={increase} dark />
        }
        {isLong &&
            <Button icon='caret-square-down' first focusOrder={0.2} onPress={decrease} dark />
        }
        <Button text={props.isNowReading ? 'Stop' : 'Read'} icon={props.isNowReading? 'volume-off' : 'volume-up'} onPress={props.onReadPress} dark/>
        <Button text='Close' icon='times' first focusOrder={0.5} onPress={props.onClosePress} dark />
      </ButtonsMoreLess>
    </ScrollableTextContainer>
  )
}

export default ScrollableText
