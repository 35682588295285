import React, { Component } from 'react'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router'

import { theme, Block, Text, Button, Img, ButtonsRow } from 'UI'

const ServiceContainer = styled.div`
  padding: ${theme.interval(2)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Item = styled(Block)`
  padding: ${theme.interval(2)};
  display: flex;
  margin-bottom: ${theme.interval(1)};
`

const Title = styled(Text.H2)`
  color: white;
  margin-bottom: ${theme.interval(1)};
`

const Photo = styled(Img)`
  border-radius: ${theme.borderRadius};
  width: 14vw;
  margin-right: ${theme.interval(2)};
`

const Texts = styled.div`
  position: relative;
`

const Response = styled(Text.H3)`
  position: relative;
  margin-top: ${theme.interval(2)};
  background: ${theme.colors.red};
  opacity: 0.8;
  box-shadow: ${theme.boxShadowSelected};
  color:white;
  border-radius: ${theme.borderRadius};
  padding: ${theme.interval(0.5, 2)};
  margin-bottom: ${theme.interval()};
`
const Buttons = styled(ButtonsRow)`
  margin: ${theme.interval(1, 0, 0, 0)};
`

const Header = styled(Text.H3)`
  margin-bottom: ${theme.interval()};
`

const RequestButton = styled(Button)`
  ${p => p.disabled && `background: ${theme.colors.red}; opacity: 0.8; color:white`}
`

// TODO Markdown support
@inject('servicesStore', 'sharedStore')
@observer
class ServicesItem extends Component {

   componentDidMount() {
    document.withNavigation?.forceRefresh()
  }

  handleStopRead = () => {
    this.props.sharedStore.stopReadText()
  }

  handleReadText = () => this.props.sharedStore.isNowReading ? this.handleStopRead() : this.handleStartRead()

  handleStartRead = () => {
    const { sharedStore, match, servicesStore } = this.props
    const {  title, description } = servicesStore.getCurrent(match.params.serviceId)
    sharedStore.readText(title + '.' + " " + description)
  }

  componentWillUnmount() {
    this.handleStopRead()
  }

  handleRequest =  () => {
    const { servicesStore ,match  } = this.props
    servicesStore.sendServiceRequest(match.params.serviceId)
    document.withNavigation?.forceRefresh()
  }

  render() {
    const { servicesStore, match, sharedStore } = this.props
    const { photo, title, description, response } = servicesStore.getCurrent(match.params.serviceId)
    const numberOfPending = servicesStore.howManyRequested(title)
    const serviceMessage = numberOfPending > 1 ? 'PENDING SERVICES' : 'PENDING SERVICE'
    return (
      <ServiceContainer>
        <Title bold>{title}</Title>
        <Item>
          <Photo src={photo} />
          <Texts>
            <Header bold>How does it work?</Header>
            <Text.H3>{description}</Text.H3>
            {numberOfPending > 0 && <Response>{numberOfPending} {serviceMessage}</Response>}
          </Texts>
        </Item>
        <Buttons>
              <RequestButton
              text={'Request service'}
              inline
              onPress={this.handleRequest}
              first
              focusOrder={0}
              disabled={false}
            />
            <Button text={sharedStore.isNowReading ? 'Stop' : 'Read'} icon={sharedStore.isNowReading? 'volume-off' : 'volume-up'} onPress={this.handleReadText} />
        </Buttons>
      </ServiceContainer>
    )
  }
}

export default withRouter(ServicesItem)
