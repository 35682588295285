import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import Newrow from 'components/Newrow'
import EventContent from 'components/EventContent'
import { YoutubeContent, ContentList } from 'components/Content'

@inject('pageStore', 'contentStore')
@observer
class ContentRoutes extends Component {


  constructor(props) {
    super(props)
    const { contentStore } = props
    contentStore.clearContent()
  }

  componentDidMount() {
    const { contentStore, match } = this.props
    contentStore.loadContentFor(match.params.page)
  }

  componentDidUpdate(prevProps) {
    const { location ,contentStore, match } = this.props
    const prevLocation = prevProps.location.pathname
    const currentLocation = location.pathname
    if (prevLocation !== currentLocation) {
      document.withNavigation.forceRefresh()
      contentStore.loadContentFor(match.params.page)
    }
  }

  render() {
    return (
      <Switch>
        <Route path={`/main/:page/content/newrow/:contentId`} component={Newrow} />
        <Route path={`/main/:page/content/event/:contentId`} component={EventContent} />
        <Route path={`/main/:page/content/youtube/:contentId`} component={YoutubeContent} />
        <Route path={`/main/:page/content`} component={ContentList} />
        <Route path={`/main/suggestions/content`} component={ContentList} />
        <Route path={`/main/suggestions-activities/content`} component={ContentList} />
        <Route path={`/main/suggestions-content/content`} component={ContentList} />
        <Route path={`/main/saved-activities/content`} component={ContentList} />
        <Route path={`/main/saved-content/content`} component={ContentList} />
      </Switch>
    )
  }
}

export default ContentRoutes
