import React, { Component } from 'react'
import styled, { css } from 'styled-components'

import { theme, Block, Icon, Text as TextUI } from 'UI/index'

import { focusable } from 'components/withNavigation/index'

const selected = css`
  box-shadow: ${theme.boxShadowSelected};
  transform: scale(1.3);
  opacity: 1;
  z-index: 1;
`

const notSelected = css`
  box-shadow: ${theme.boxShadow};
  opacity: 0.8;
  transform: scale(1)};
`

const ActionBody = styled(Block)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${theme.interval(4)};
  height: ${theme.interval(4)};
  ${p => p.color && `background: ${p.color}; color: white;` }
  &:not(:last-child) { margin-bottom: ${theme.interval()}; }
  ${p => p.selected ? selected : notSelected}
`

const StyledIcon = styled(Icon)`
  font-size: ${theme.fontHeading3};
  ${p => p.iconColor && `color: ${p.iconColor}`}
`

const Text = styled(TextUI)`
  
`

class Action extends Component {

  componentDidMount() {
    const { setCallback, onPress } = this.props
    if (onPress) setCallback(onPress)
  }

  render() {
    const { icon, text, selected, color, iconColor, forwardedRef } = this.props
    return (
      <ActionBody ref={forwardedRef} selected={selected} color={color}>
        {icon && <StyledIcon icon={icon} iconColor={iconColor} />}
        {text && <Text>{text}</Text>}
      </ActionBody>
    )
  }
}

export default focusable(Action, { isSub: true })
