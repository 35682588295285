import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { observable } from 'mobx'
import { observer, inject } from 'mobx-react'
import tinycolor from 'tinycolor2'
import { Textfit } from 'react-textfit';

import Clock from 'components/Clock'
import Version from 'components/Version'
import { theme, Text } from 'UI'
import { cutTextWhenNeeded } from 'utils/utilFunctions'

const bgImage = css`
  background-image:${p => p.color ? `linear-gradient(to right bottom, ${tinycolor(p.color).lighten(40).toHexString()}, ${tinycolor(p.color).darken(10).toHexString()})` : ''};
  background-size: 100vw 100vh;
`

const HeaderContainer = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 2;
`

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  position: relative;
  z-index: 5;
  padding: 0 2vw;
  margin: 0 3vw 5px 1vw;
`

const HeaderBackWrap = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  height: 21vh;
  width: 100%;
  overflow: hidden;
`

const HeaderBack = styled.div`
  position: absolute;
  z-index: 4;
  top: 0;
  left: -28vw;
  width: 100vw;
  height: 100vh;
  ${bgImage};
`

const StyledClock = styled(Clock)`
  font-size: ${theme.fontHeading1};
  .date {
    font-size: ${theme.fontHeading3};
  }
`

const TitleWrap = styled.div`
  position: relative;
  border-radius: 0 0 ${theme.borderRadius} ${theme.borderRadius};
  box-shadow: 0 3px 5px 1px rgba(0,0,0,0.2);
  background-color: rgba(0,0,0,0.15);
  flex: 1;
  padding: ${theme.interval(0, 2)};
  margin-right: 2vw;
  text-align: center;
  transition: ${theme.css.transition};
  height: 16vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vh;
  
  &:before, &:after {
    display: block;
    content: '';
    position: absolute;
    top: -3px;
    width: 4vh;
    height: calc(17vh + 3px);
    ${bgImage};
    background-size: 100vw 100vh;
  }
  
  &:before {
    left: -1vh;
    border-top-right-radius: 4vh;
    background-position: -31vw 0;
    box-shadow: inset -2px 3px 3px 0px rgba(0,0,0,0.2);
  }
  
  &:after {
    right: -1vh;
    border-top-left-radius: 4vh;
    background-position: -73.5vw 0;
    box-shadow: inset 2px 3px 3px 0px rgba(0,0,0,0.2);
  }
`

const TitleBottom = styled.div`
  position: absolute;
  bottom: calc(-1vh);
  left: 0vh;
  right: 0vh;
  box-sizing: border-box;
  ${bgImage};
  background-position: -31vw -16vh;
  z-index: 3;
  overflow: hidden;
  height: 2vh;
  
  &:before {
    position: absolute;
    top: -2px;
    height: 1vh;
    left: calc(3vh - 1px);
    right: calc(3vh - 1px);
    content: '';
    z-index: 4;
    ${bgImage};
    background-position: -31vw -18vh;
    border-radius: 0 0 ${theme.borderRadius} ${theme.borderRadius};
  }
  
  &:after {
    position: absolute;
    top: -2px;
    height: 1vh;
    left: calc(3vh - 0px);
    right: calc(3vh - 0px);
    content: '';
    z-index: 4;
    background-color: rgba(0,0,0,0.15);
    border-radius: 0 0 ${theme.borderRadius} ${theme.borderRadius};
    box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.2);
  }
`

const CustomTitle = styled(Text.H1)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.1em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width:100%;
`

@inject('pageStore')
@observer
class Header extends Component {

  @observable isDisabled = false

  get title() {
    const { pageStore, title } = this.props
    return title || pageStore.title || pageStore.currentPage || 'Main'
  }

  handleClick = () => {
    this.isDisabled = !this.isDisabled
    document.withNavigation.setDisabled(this.isDisabled)
  }

  render() {
    const { pageStore } = this.props

    return (
      <HeaderContainer>
        <HeaderBackWrap>
          <HeaderBack color={pageStore.color} />
        </HeaderBackWrap>
        <HeaderContent>
          <TitleWrap color={pageStore.color}>
            {/*<Before color={pageStore.color} />*/}
            <CustomTitle bold >
              <Textfit>
                {this.title}
              </Textfit>
            </CustomTitle>
            <TitleBottom color={pageStore.color} />
            {/*<After color={pageStore.color} />*/}
          </TitleWrap>
          <StyledClock date time />
        </HeaderContent>
      </HeaderContainer>
    )
  }
}

export default Header