import React, { Component } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import { observable ,computed} from 'mobx'
import { observer, inject } from 'mobx-react'

import { theme, Button } from 'UI'
import jsbridge from 'utils/jsbridge'

const Item = styled(Button)`
  margin-bottom: ${theme.interval(1.5)};
`

@inject('messagesStore', 'eventsStore')
@observer
class SideMenuItem extends Component {
  
  handlePress = () => {
    const { handlePress, href } = this.props
    if (handlePress) {
      handlePress()
    } else {
      this.goToHref()
    }
  }

  goToHref = () => {
    const { history, href } = this.props
    jsbridge.call('openPage', href)
    history.push(`/main/${href}`)
  }

  getBadge = () => {
    const { href, messagesStore, eventsStore } = this.props
    if (href === 'messages') return messagesStore.onlyNewMessages().length 
    if (href === 'diary') return eventsStore.todayNotHappenedYet().length
    return false
  }

  render() {
    const { title, icon, href, messagesStore, handlePress, ...rest } = this.props
    return (
      <Item text={title} icon={icon} onPress={this.handlePress} badge={this.getBadge()} name={`SideMenuItem_${href}`} {...rest} />
    )
  }
}

export default withRouter(SideMenuItem)
