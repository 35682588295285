import React, { isValidElement, cloneElement, useState, useCallback, useRef } from 'react'
import styled from 'styled-components'

import { area } from 'components/withNavigation'

import theme from 'UI/theme'
import ListItem from './ListItem'

const StyledList = styled.div`
  transition: ${theme.transition};
  padding: ${theme.interval(2)} ${theme.interval(6)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const withFocusOrder = (item, index) => ({ ...item, key: item.key || `ListItem_${index + 1}`, props: { ...item.props, focusOrder: item.props.focusOrder || index + 1 }})

// TODO Some ugly code - need curry it
const shouldRender = (listIndex, currentIndex, renderItems) => {
  return shouldRenderMore(listIndex, currentIndex, renderItems) && shouldRenderLess(listIndex, currentIndex, renderItems)
}

const shouldRenderMore = (listIndex, currentIndex, renderItems) => {
  const plusMinus = Math.floor((renderItems - 1) / 2)
  return (listIndex >= currentIndex - plusMinus)
}

const shouldRenderLess = (listIndex, currentIndex, renderItems) => {
  const plusMinus = Math.floor((renderItems - 1) / 2)
  return (listIndex <= currentIndex + plusMinus)
}

function List({children, renderItems = 7, ...props}) {
  const list = useRef()
  const [ currentIndex, setIndex ] = useState(0)

  const setCurrentIndex = useCallback(newIndex => {
    setIndex(newIndex)
  }, [currentIndex])

  return (
    <StyledList ref={list} {...props}>
      {children.length === undefined
        ? cloneElement(children)
        : children
            .map(withFocusOrder)
            .map((child, index) => isValidElement(child) && cloneElement(child,
                {
                  listIndex: index,
                  setCurrentIndex: setCurrentIndex,
                  shouldRender: true
                }
              ))
            .filter((item, index) => shouldRenderLess(index, currentIndex, renderItems))
      }
    </StyledList>
  )
}

List.Item = ListItem

export default area(List)
