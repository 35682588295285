import React, { Component } from 'react'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import { observable, action } from 'mobx'

import { theme, Button, Text, Input } from 'UI'

const SwitchUserContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
`
const SwitchUserForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`
const UserWrapper = styled.div`
    word-wrap: break-word;
    color: #000;
    padding: 20px;
    margin: 10px 0;
    font-size: 80%;
`

const Field = styled.div`
  margin: ${theme.interval(1.5)} 0;
  text-align: center;
`

const StyledInput = styled(Input)`
  width: 35vw;
`

const MessageWrap = styled.div`
  overflow: hidden;
`

const Message = styled(Text.H3)`
  width: 35vw;
  transition: all 300ms ease-in-out;
  transform: translateY(${ p => p.hasMessage ? '0' : '-31px'});
`

@inject('userStore')
@observer
class SwitchUser extends Component {

  @observable email = ''
  @observable pincode = ''

  @action
  setEmail = e => {
    const { userStore } = this.props
    userStore.error = null
    this.email = e.currentTarget.value
  }

  @action
  setPincode = e => {
    const { userStore } = this.props
    userStore.error = null
    this.pincode = e.currentTarget.value
  }

  @action
  sendPincode = () => {
    const { userStore } = this.props
    if (!this.email) userStore.error = "Email shouldn't be empty"
    if (!userStore.error) userStore.sendPincode(this.email)
  }

  @action
  login = () => {
    const { userStore , history } = this.props
    if (!this.pincode) userStore.error = "Pincode shouldn't be empty"
    if (!this.pincode && !this.email) userStore.error = "Email and pincode shouldn't be empty"
    if (!userStore.error) userStore.tryLogin(this.email, this.pincode, history)
  }

  render() {
    const { userStore } = this.props

    return (
      <SwitchUserContainer>
        <SwitchUserForm>
          <Field>
            <Text.H1>Switch User</Text.H1>
          </Field>
          <Field>
            <StyledInput placeholder='Enter email' onChange={this.setEmail} onPress={this.sendPincode} type='email' />
          </Field>
          <Field>
            <StyledInput placeholder='Enter pincode' onChange={this.setPincode} onPress={this.login} type='text' />
          </Field>
          <MessageWrap>
            <Message hasMessage={userStore?.message || userStore?.error}>
              {userStore?.error || userStore?.message}
            </Message>
          </MessageWrap>
          <UserWrapper>
            {`Webapp environment: ${process.env.NODE_ENV} v-0.1.0` /* TODO Need to pass here actual version */}
          </UserWrapper>
        </SwitchUserForm>
      </SwitchUserContainer>
    );
  }
}
export default SwitchUser
