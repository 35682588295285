import { observable, action } from 'mobx'
import fetcher from 'utils/fetcher'
import WEATHER_CONDITIONS_CODE from 'utils/weatherIcons'

class WelcomeStore {
  @observable location = null
  @observable weather = null

  @action
  getLocation = async () => {
    try {
      const data = await fetcher.get(`${process.env.REACT_APP_IP_API}/json`, { dontUseToken: true })
      this.location = { latitude: data.lat, longitude: data.lon }
    } catch (error) {
      console.log(error?.message)
    }
  }

  @action
  getWeather = async () => {
    try {
      await this.getLocation()
      const data = await fetcher.get(`${process.env.REACT_APP_WEATHER_SERVICE_BASIC_URL}&APPID=${process.env.REACT_APP_WEATHER_SERVICE_APPID}&lat=${this.location.latitude}&lon=${this.location.longitude}`, { dontUseToken: true })

      const temp = Math.round(data.main.temp)

      const code = temp !== data.weather && data.weather.length > 0 ? data.weather[0].id : Infinity; // Infinity keeps code working but weather icons wont show up
      if (code) {
        let iconId = WEATHER_CONDITIONS_CODE[code] ? WEATHER_CONDITIONS_CODE[code].icon : ''
        if (!(code > 699 && code < 800) && !(code > 899 && code < 1000)) {
          iconId = `day-${iconId}`;
        }
        this.weather = {
          temp,
          icon: ` wi wi-${iconId}`, // YAHOO_WEATHER_CONDITIONS_CODE[weatherCondition].wiId,
        }
      }
    } catch (e) {
      console.log('---', e)
    }
  }
}

export const welcomeStore = new WelcomeStore()
export default welcomeStore
