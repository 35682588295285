import React from 'react'
import styled, { css } from 'styled-components'

import theme from 'UI/theme'

// TODO Add more predefined styled variants

const thin = css`
  font-weight: 300;
`

const bold = css`
  font-weight: 700;
`

const defaultCss = css`
  word-break: normal;
  font-size: ${theme.fontText};
  ${props => props.thin && thin};
  ${props => props.bold && bold};
`

const Heading = styled.div`
  ${defaultCss};
`

const P = styled.p`
  ${defaultCss};
  line-height: 1.1;
  margin: 0;
`

const Span = styled.span`
  ${defaultCss}
`

const Heading1 = styled(Heading)`
  font-size: ${theme.fontHeading1};
`

const Heading2 = styled(Heading)`
  font-size: ${theme.fontHeading2};
`

const Heading3 = styled(Heading)`
  font-size: ${theme.fontHeading3};
`

const Heading4 = styled(Heading)`
  font-size: ${theme.fontHeading4};
`

const TextFactory = ({ variant, ...props }) => {``
  const vars = {
    span: <Span {...props} />,
    p: <P {...props} />,
    h1: <Heading1 {...props} />,
    h2: <Heading2 {...props} />,
    h3: <Heading3 {...props} />,
    h4: <Heading4 {...props} />
  }
  return vars[variant] || vars['span']
}

const Text = ({ v='span', ...props }) => <TextFactory {...props} variant={v} />

Text.H1 = ({ v='h1', ...props }) => <TextFactory {...props} variant={v} />
Text.H2 = ({ v='h2', ...props }) => <TextFactory {...props} variant={v} />
Text.H3 = ({ v='h3', ...props }) => <TextFactory {...props} variant={v} />
Text.H4 = ({ v='h4', ...props }) => <TextFactory {...props} variant={v} />
Text.P = ({ v='p', ...props }) => <TextFactory {...props} variant={v} />

export default Text
