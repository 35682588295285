import { observable, action, computed, toJS, when } from 'mobx'

import { api } from 'utils/fetcher'
import bi from 'utils/BI'

class UserStore {

  constructor() {
    when(
      () => !!this.userId,
      () => bi.send.log.with('event', 'appStart', { userId: this.userId })
    )
    const userString = window.JSInterface && JSON.parse(window.JSInterface.jsReady())?.json
    const user = userString && JSON.parse(userString)
    this.userData = JSON.parse(localStorage.getItem('userData')) || user
  }

  @observable message = null
  @observable error = null

  @observable userData = null

  @action
  getProfile = async () => {
    const profile = await api.get().to.user.profile()
    if (this.userData) {
      this.userData.profile = profile;
    }
  }

  @action
  sendPincode = async email => {
    const body = { email }
    const response = await api.post(body).to.user.pin()
    if (response?.result === 'ok') {
      this.error = null
      this.message = `Email with pincode has been send to ${email}`
      document.withNavigation?.pressKey('arrowDown')
    }
    const message = response?.message || 'Something goes wrong'
    if (response?.result === 'error') this.error = `${message}`
  }

  @action
  tryLogin = async (email, pin, history) => {
    const body = {
      email,
      pin
    }
    const response = await api.post(body).to.user.login()
    if (response?.result === 'not authorized') this.error = 'Wrong email or pincode'
    if (response?.result === 'ok') {
      this.userData = response
      localStorage.setItem('userData', JSON.stringify(response))
      history.push('/welcome') // Dirty history hack =(((
    }
  }

  @computed
  get userId() {
    return this.userData?.id || undefined
  }

  @computed
  get username() {
    return this.userData?.profile.user_name || undefined
  }

  @computed
  get token() {
    return this.userData?.token || undefined
  }

  @computed
  get isAuth() {
    return !!this.token
  }

}

export const userStore = new UserStore()
export default userStore
