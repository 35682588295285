import React, { Component, useEffect, useContext, createContext, useState, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { observable } from 'mobx'
import { observer, inject } from 'mobx-react'

import styled from 'styled-components'
import uuid from 'uuid/v4'

import { theme } from 'UI'

import { area } from 'components/withNavigation'

const ModalContainer = styled.div`
  transition: all 200s ease-in-out;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 90;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.4);
  transform: translate(0);
`

const ModalContent = styled.div`
  position: absolute;
  z-index: 91;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 20vw;
  min-height: 20vw;
  max-height: 90%;
  box-shadow: ${theme.boxShadowSelected};
  border-radius: ${theme.borderRadius};
  background: white;
  transition: ${theme.transition};
  display: flex;
  flex-direction: column;
`

// @inject('sharedStore')
// @observer
class AAAModal extends Component {

  componentDidMount() {
    const { onBack } = this.props
    if (onBack) document.withNavigation?.setBackCallback(onBack)
    if (!document.getElementById('modalPortal')) {
      document.withNavigation?.setShouldStoreFocusableTo(true)
    }
    document.withNavigation?.forceRefresh()
  }

  componentWillUnmount() {
    const { sharedStore } = this.props
    sharedStore.stopReadText()

    document.withNavigation?.setBackCallback()
    document.withNavigation?.forceRefresh()
  }

  render() {
    const { onBack, children, ...props } = this.props
    return createPortal(
      <ModalContainer id='modalPortal'>
        <ModalContent {...props}>
          {children}
        </ModalContent>
      </ModalContainer>,
      document.getElementById('root')
    )
  }
}


const useExist = name => {
  const [ thereAre , setIsExist ] = useState([])
  const length = thereAre.length
  
  const wholeExistence = createContext({
    existence: thereAre,
    admitToExistence: name => setIsExist(state => [...state, name]),
    banishFromExistence: name => setIsExist(state => state.filter(item => item !== name))
  })
  
  useEffect(() => {
    setIsExist(state => [...state, name])
    return () => {
      setIsExist(state => state.filter(item => item === name))
    }
  }, [])
  
  return useMemo(() => bindToExist, [length])
}

const Modal = ({ children, onBack, sharedStore, shouldntStoreFocusable, ...props }) => {
  
  useEffect(() => {
    document.withNavigation?.setShouldStoreFocusableTo(true)
    if (onBack) document.withNavigation?.setBackCallback(onBack)
    document.withNavigation?.forceRefresh()
    
    return () => {
      sharedStore.stopReadText()
      if (!shouldntStoreFocusable) document.withNavigation?.setShouldStoreFocusableTo(false)
      document.withNavigation?.setBackCallback()
      document.withNavigation?.forceRefresh()
    }
  }, [])
  
  return createPortal(
    <ModalContainer id='modalPortal'>
      <ModalContent {...props}>
        {children}
      </ModalContent>
    </ModalContainer>,
    document.getElementById('root')
  )
}

export default inject('sharedStore')(observer(area(Modal, { onlyArea: true })))
