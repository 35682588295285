import React, { Component, createRef } from 'react'
import styled from 'styled-components'
import { observable, action, computed, toJS } from 'mobx'
import { observer, inject } from 'mobx-react'
import { isBefore, format, parseISO } from 'date-fns'

import config from 'utils/config'
import { ContentDetails, Thumb } from 'components/Content'
import { theme, Text, Button, Modal, ScrollableText, ButtonsRow } from 'UI'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70vh;
`

const Controls = styled(ButtonsRow)`
  margin-top: ${theme.interval(2)};
  justify-content: space-evenly;
`

const FixedButton = styled(Button)`
  width: 14vw;
`

const Title = styled(Text.H2)`
  color: white;
`

const Price = styled(Text.H2)`
  color: white;
`

const ModalTitle = styled(Text.H2)`
  margin-bottom: ${theme.interval()};
`

const MessageModal = styled.div`
  min-width: 55vw;
  height: 100%;
  padding: ${theme.interval(2)};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Buttons = styled(ButtonsRow)`
  margin-top: ${theme.interval()};
`

const Header = ({ starts, content_address, ...props }) => <Title><Text.H2>{starts}<br />{content_address}</Text.H2></Title>

const PLACEHOLDER_TEXT = 'Please, load this screen from content list'
const TEXT = 'The result is a spiritual monkey.As i have visualized you, so you must need one another.When the guru of resurrection views the graces of the sinner, ' +
  'the intuition will know therapist.The truth is a psychic aspect.Going to the realm of blessing doesn’t illuminate man anymore than needing creates ultimate attraction.' +
  'Attraction doesn’t extraordinarilly praise any explosion of the man — but the source is what shines.The beauty is a bright follower.Faith happens when you discover reincarnation ' +
  'so agreeable that whatsoever you are contacting is your man.Volume, justice and a further psychic world.Not heavens or chaos, illuminate the suffering.You have to shine, ' +
  'and hurt acceptance by your existing. The issue is an alchemistic individual. An outer form of emptiness is the enlightenment. Our mysterious conclusion for heaven is to feel others cosmically.'

@inject('pageStore', 'contentStore','sharedStore')
@observer
class EventContent extends Component {

  @observable isRSVPShow = false
  @observable isDetailsExpanded = false

  componentWillUnmount() {
    const { pageStore } = this.props
    pageStore.setTitle(undefined)
    this.handleStopRead()
  }

  text = createRef()

  saveContent = () => {
    const { contentStore, content_id } = this.props
    contentStore.saveContent(content_id)
  }

  @action handleRSVPToggle = () => this.isRSVPShow = !this.isRSVPShow

  @action openDetails = () => {
    this.isDetailsExpanded = true
  }

  @action closeDetails = () => {
    this.isDetailsExpanded = false
    this.handleStopRead()
  }
  
  handleStartRead = what => {
    const { contentStore, match, sharedStore } = this.props
    const { content_header, content_address, content_start_date, content_price, content_markup, content_rsvp } = contentStore.contentFor(match.params.contentId)
    
    const starts = content_start_date ? format(parseISO(content_start_date), config.dateTimeFormat) : ''
    const text = what === 'rsvp' ? content_rsvp : content_header + '.' + ' '  + content_address + '.' + ' ' + starts + '.' + ' ' + content_price + '.' + ' ' + content_markup
    
    sharedStore.readText(text)
  }
  
  handleReadText = what => () => this.props.sharedStore.isNowReading ? this.handleStopRead() : this.handleStartRead(what)

  handleStopRead = () => {
    const { sharedStore } = this.props
    sharedStore.stopReadText()
  }
  
  render() {
    const { contentStore, match,sharedStore } = this.props
    const {
      content_thumbnail,
      content_rsvp,
      content_header,
      content_start_date,
      content_price,
      content_youtube_id,
      content_address,
      content_markup,
      ...rest
    } = contentStore.contentFor(match.params.contentId)
    const starts = content_start_date ? format(parseISO(content_start_date), config.dateTimeFormat) : ''
    const text = content_markup?.replace(/(<\/?[^>]+(>|$))|(&nbsp;)|(&amp;)|(null)/g, ' ') || ''

    return (
      <ContentDetails>
        <ContentContainer>
          <Thumb src={content_thumbnail} header={<Header starts={starts} content_address={content_address} />} footer={<Price bold>{content_price}</Price>} />
          <Controls>
              <Button text='Details' icon='file-alt' first focusOrder={1} onPress={this.openDetails} />
              <Button text='RSVP' icon='calendar-alt' onPress={this.handleRSVPToggle} marginLeft marginRight />
          </Controls>
          {this.isDetailsExpanded && (
            <Modal onBack={this.closeDetails}>
              <MessageModal>
                <ModalTitle bold>{content_header}</ModalTitle>
                <ScrollableText isNowReading={sharedStore.isNowReading} onReadPress={this.handleReadText('details')} onClosePress={this.closeDetails}>
                  <Text.H2 thin>{content_address}, Price: {content_price}</Text.H2>
                  <Text.H2 thin>{text}</Text.H2>
                </ScrollableText>
              </MessageModal>
            </Modal>
          )}
          {this.isRSVPShow && (
            <Modal onBack={this.handleRSVPToggle}>
              <MessageModal>
                  <ModalTitle bold>{content_header}</ModalTitle>
                  <Text.H2 thin>{content_rsvp}</Text.H2>
                <Buttons>
                  <FixedButton text={sharedStore.isNowReading? 'Stop' : 'Read'} icon={sharedStore.isNowReading ? 'volume-off' : 'volume-up'} onPress={this.handleReadText('rsvp')} dark />
                  <Button text='Close' inline onPress={this.handleRSVPToggle} dark first focusOrder={0} />
                </Buttons>
              </MessageModal>
            </Modal>
          )}
        </ContentContainer>
      </ContentDetails>
    )
  }
}

export default EventContent
