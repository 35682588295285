import { observable, action, computed, toJS } from 'mobx'
import { fromPromise } from 'mobx-utils'

import { api } from 'utils/fetcher'
import { updateIn, deleteFrom, withStatusFor } from 'utils/storeHelpers'

class MessagesStore {
  @observable _storedMessages = []
  @observable _messages = fromPromise(api.get().from.messages.all())
  @computed get link() { return this._messages.value?.messages }
  @computed get length() { return this.messages.length }
  @computed get messages() { return (this.isLoading ? toJS(this._storedMessages) : toJS(this.link)) || [] }

  @action
  getMessages = () => {
    this._storedMessages = this.messages
    this._messages = fromPromise(api.get().from.messages.all())
  }

  @action
  deleteMessage =  async messageId => {
    const body = { ids: [messageId] }
    deleteFrom(this.link).where('id', messageId)
    await api.post(body).to.messages.delete()
    setTimeout(() => {
      this.getMessages()
    }, 500)
  }

  @action
  markReadMessage = async messageId => {
    const response = await api.post({ ids: [messageId] }).to.messages.read()
    if (response.status === 'ok') {
      if(this.link) {
        updateIn(this.link).where('id', messageId).to('recipient_status_display', 'Viewed')
      }
    }
  }

  refreshStore = () => this.getMessages()

  onlyNewMessages() {
    return this.messages.filter(message => message.recipient_status_display === 'New') || []
  }
}

export const messagesStore = new MessagesStore()
export default withStatusFor(messagesStore, { withMainField: '_messages' , onPath:'\/main\/messages'})
