import ContentList from './ContentList'
import ContentListItem from './ContentListItem'
import YoutubeContent from './YoutubeContent'
import ContentRoutes from './ContentRoutes'
import ContentDetails from './ContentDetails'
import Thumb from './Thumb'

export {
  ContentList,
  ContentListItem,
  YoutubeContent,
  ContentRoutes,
  ContentDetails,
  Thumb
}

export default ContentRoutes
