import React, { Component, createRef } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { observable, computed, action } from 'mobx'
import { observer, inject } from 'mobx-react'

import { Button, Img, Modal, theme,Icon } from 'UI'
import PhotosItem from 'components/Photos/PhotosItem'

import { area } from 'components/withNavigation'

const indicate = keyframes`
  0% {
    transform: scale(1); background: rgba(255,255,255,0.6);
  }
  50% {
    transform: scale(1.3); background: rgba(255,255,255,0.9);
  }
  100% {
    transform: scale(1); background: rgba(255,255,255,0.6);
  }
`

const indicateCss = css`
  animation: ${indicate} 1200ms ease-in-out 1;
`

const fade = moved => keyframes`
  0% {
    opacity: 0.01;
    transform: translateX(${moved === 'left' ? '-50%' : '50%'});
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
`

const fadeCss = moved => css`
  animation: ${fade(moved)} 400ms ease-in-out 1;
`

const PhotosContainer = styled.div`
  transition: ${theme.transition};
  margin-left: 5vw;
`

const StyledPhotosItem = styled(PhotosItem)`
  overflow: visible;
`

const PhotoModal = styled(Modal)`
  width: 96vw;
  height: 92vh;
  background: transparent;
  border-radius: ${theme.borderRadius};
  display: flex;
  justify-content: center;
  box-shadow: none;
`

const ImgWrap = styled.div`
  margin: 0 auto;
  transition: ${theme.transition};
  opacity: 1;
  ${p => p.isMoved && fadeCss(p.isMoved)};
  display: flex;
  justify-content: center;
  position: relative;
`

const ModalImg = styled(Img)`
  height: 92vh;
  border-radius: ${theme.borderRadius};
  transition: ${theme.transition};
  opacity: ${p => p.isLoaded ? 1 : 0.01};
`

const HiddenImg = styled(ModalImg)`
  position: absolute;
  top: 0;
`

const PrevImg = styled(HiddenImg)`
  left: -100%;
  opacity: ${p => p.isMoved === 'left' ? 1 : 0.5};
`
const NextImg = styled(HiddenImg)`
  right: -50%;
  opacity: ${p => p.isMoved === 'right' ? 1 : 0.5};
`

const Close = styled.div`
  position: absolute;
  bottom: ${theme.interval()};
  left: 50%;
  transform: translateX(-50%);
`
const Control = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`
const Prev = styled(Control)`
  left: 0;
`

const Next = styled(Control)`
  right: 0;
`

const ControlButton = styled(Button)`
  background: rgba(255,255,255,0.6);
  color: ${theme.colors.base};
  ${p => p.moved && indicateCss}
`


const SearchIcon = styled.div`
  position: absolute;
  top: -1vw;
  right: -1vw;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4vw;
  height: 4vw;
  border-radius: ${theme.borderRadius};
  font-size: ${theme.font.fontHeading2};
  color: ${theme.colors.red};
  background: white;
`

const ImageOnTop = props => props.selected && <SearchIcon {...props}><Icon icon='search'/></SearchIcon>

const Gallery = ({ photos, currentIndex, openModal, ...props }) => photos.map((photo, index) =>
 
<StyledPhotosItem   key={`photo_${photo.id}`} onPress={openModal} currentIndex={currentIndex} first focusOrder={index+1} {...photo} {...props}  injectComponent={<ImageOnTop  icon='search' />}
/>
  
)

@inject('photosStore')
@observer
class Photos extends Component {

  @observable isModalOpen = false
  @observable currentIndex = undefined
  @observable isImgLoaded = false
  @observable moved = undefined

  componentDidMount() {
    const { photosStore } = this.props
    const { setCallback } = document.withNavigation.keyCallback
    setCallback(this.handleKeys)
    photosStore.getPhotos()
  }

  componentWillUnmount() {
    document.withNavigation?.keyCallback.shouldUse(false)
  }

  @action
  openModal = id => {
    this.isImgLoaded = false
    this.isModalOpen = true
    this.currentIndex = this.getPhotoIndex(id)
    
    document.withNavigation?.keyCallback.shouldUse(true)
  }

  getPhotoIndex = id => {
    const { photosStore } = this.props
    return photosStore.photos?.findIndex(item => item.id === id)
  }

  @action
  closeModal = () => {
    this.isModalOpen = false
    document.withNavigation?.keyCallback.shouldUse(false)
  }

  @action
  makeOutstandingMove = button => {
    this.moved = button
    this.isImgLoaded = false
    setTimeout(() => this.moved = undefined, 1200) // Time of flash of arrow buttons
  }

  @action
  handleKeys = keyCode => {
    const { photosStore } = this.props
    const getNewIndex = index => {
      if( photosStore.photos.length === 1 ){return 0}
      if (keyCode === 37 && index === 0) { this.makeOutstandingMove('left'); return photosStore.photos.length - 1 }
      if (keyCode === 37 && index !== 0) { this.makeOutstandingMove('left'); return index - 1 }
      if (keyCode === 39 && index === photosStore.photos.length - 1) { this.makeOutstandingMove('right'); return 0 }
      if (keyCode === 39 && index !== photosStore.photos.length - 1) { this.makeOutstandingMove('right'); return index + 1 }
      return index
    }
    this.currentIndex = getNewIndex(this.currentIndex)
  }

  getCurrentSource = forImg => {
    const { photosStore } = this.props
    const prevIndex = this.currentIndex - 1 >= 0 ? this.currentIndex - 1 : photosStore.photos.length - 1
    const nextIndex = this.currentIndex + 1 <= photosStore.photos.length - 1 ? this.currentIndex + 1 : 0

    if (forImg === 'prev') return photosStore.photos[prevIndex]?.photo
    if (forImg === 'current') return photosStore.photos[this.currentIndex]?.photo
    if (forImg === 'next') return photosStore.photos[nextIndex]?.photo
  }

  @action
  handleLoadImg = () => {
    this.isImgLoaded = true
  }

  render() {
    const { photosStore } = this.props
    return (
      <PhotosContainer>
        <Gallery photos={photosStore.photos} currentIndex={this.currentIndex}  openModal={this.openModal}/>
        
        {this.isModalOpen && (
          <PhotoModal onBack={this.closeModal} >
            <ImgWrap isMoved={this.moved}>
              {/*<PrevImg src={this.getCurrentSource('prev')} isMoved={this.moved} />*/}
              <ModalImg icon='search' src={this.getCurrentSource('current')} handleLoad={this.handleLoadImg} isMoved={this.moved} isLoaded={this.isImgLoaded} key={this.currentIndex}/ >
              
              {/*<NextImg src={this.getCurrentSource('next')} isMoved={this.moved} />*/}
            </ImgWrap>
            <Close>
              <Button text='Close' icon='ok' dark first focusOrder={0} onPress={this.closeModal} />
            </Close>
            <Prev><ControlButton icon='caret-square-left' disabled moved={this.moved === 'left'} /></Prev>
            <Next><ControlButton icon='caret-square-right' disabled moved={this.moved === 'right'} /></Next>
          </PhotoModal>
        )}
      </PhotosContainer>
    )
  }
}

export default area(Photos, { oneDimension: true })
