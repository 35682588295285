import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { observable } from 'mobx'
import { observer, inject } from 'mobx-react'

import interest from 'assets/images/interest_new.jpg'

import { theme, Img, Text, Block, Spinner } from 'UI'

const InterestsItemContainer = styled.div`
  width: 33vw;
  height: 66vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const Card = styled(Block)`
  position: relative;
  border-radius: ${theme.interval()};
  height: 45vh;
  width: 17vw;
  margin: ${theme.interval()};
  background: url(${p => p.src || interest}) center center;
  background-size: cover;
  display: flex;
  justify-content: space-between;
`

const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`

const Title = styled(Text.H2)`
  color: #fff;
  text-align: center;
  flex-grow: 0;
  overflow: hidden;
  margin-top: ${theme.interval(2)};
`

const fadedLike = css`
  opacity: 0.3;
  transform: rotate(5deg) translate(60px, -5px) ;
`

const fadedDislike = css`
  opacity: 0.3;
  transform: rotate(-5deg) translate(-60px, 5px) ;
`

const notFaded = css`
  opacity: 1;
  transform: translate(0, 0)  rotate(0deg);
`

const AnimatedCard = styled(Card)`
  transition: ${theme.transition};
  ${p => p.animatingFor === undefined ? notFaded : p.animatingFor === 'like' ? fadedLike : fadedDislike}
`

const Wrap = styled.div`
  position: relative;
  flex-shrink: 0;
  margin-left: ${theme.interval(10)};
`

@inject('interestStore')
@observer
class InterestsItem extends Component {
  render() {
    const { interestStore,  animatingFor, like, dislike, ...props } = this.props
    const { title, photo } = interestStore?.currentItem

    return (
      <InterestsItemContainer>
        <TitleWrap><Title>{title}</Title></TitleWrap>
        <Wrap>
          {like}
          <AnimatedCard {...props} src={photo} animatingFor={animatingFor} />
          {dislike}
        </Wrap>
      </InterestsItemContainer>
    )
  }
}

export default InterestsItem
