import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Bb = styled.b`
  font-size: 1.1em;
  font-weight: 900;
`

import jsbridge from 'utils/jsbridge'

function Version({ ...rest }) {

  const [isVisible, setVisible] = useState(false)
  const [version, setVersion] = useState({ env: null, webApp: null, tvApp: null})

  useEffect(() => {
    const notProd = process.env.REACT_APP_API !== 'https://backend.sparko.tv/'
    setVisible(notProd)

    const appInfo = jsbridge.call('getAppInfo')
    const info = JSON.parse(appInfo)

    const currentVersion = {
      env: info.env,
      isLocalApp: info.isLocalApp,
      webApp: process.env.APP_VERSION,
      tvApp: info.tvVersion,
      network: info.network,
      ipAddress: info.ipAddress
    }

    setVersion(currentVersion)

  }, [isVisible])

  return ( isVisible &&
    <div {...rest}>
      <Bb> env:</Bb>{version.env || '-'}
      <Bb> web:</Bb>{version.webApp || '-'}
      <Bb> tv:</Bb>{version.tvApp || '-'}
      <Bb> net:</Bb>{version.network || '-'}
      <Bb> ip:</Bb>{version.ipAddress || '-'}
    </div>
  )
}

export default Version
