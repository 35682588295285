import { observable, action, computed, toJS, reaction } from 'mobx'
import { fromPromise } from 'mobx-utils'

import { api } from 'utils/fetcher'
import { updateIn, deleteFrom, withStatusFor } from 'utils/storeHelpers'

const byLastThenFirst = (a, b) => {
  if (a.id > b.id) {
    return -1;
  } else if (a.id < b.id) {
    return 1;
  }
}

class PhotosStore {
  
  constructor() {
    reaction(
      () => this.photos.length > 0,
      isNotZero => { if (isNotZero) { this.preloadImages(this.photos); document.withNavigation.forceRefresh() }}
    )
  }
  
  @observable _items = fromPromise(api.get().from.photos.all())
  @computed get link() { return this._items?.value?.sort(byLastThenFirst) }
  @observable _storedItems = []
  @computed get photos() { return (this.isLoading ? toJS(this._storedItems.sort(byLastThenFirst)) : toJS(this.link)) || [] }

  @action
  getPhotos = () => {
    this._storedItems = this.photos
    this._items = fromPromise(api.get().from.photos.all())
  }

  preloadImages = data => {
    const head = document.head
    for (let id in data) {
      if (data[id].photo) {
        let link = document.createElement('link')
        link.href = data[id].photo || ''
        link.rel = 'preload'
        link.as = 'image'
        head.appendChild(link)
      }
    }
  }

  refreshStore = () => this.getPhotos()

}

export const photosStore = new PhotosStore()
export default withStatusFor(photosStore, { withMainField: '_items', onPath: '\/main\/photos' })
