import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { Detector } from 'react-detect-offline'

import Routes, { router } from './Routes'
import { Provider as MobxProvider } from 'mobx-react'
import stores from 'stores'

import eot from 'weather-icons/font/weathericons-regular-webfont.eot'
import svg from 'weather-icons/font/weathericons-regular-webfont.svg'
import ttf from 'weather-icons/font/weathericons-regular-webfont.ttf'
import woff from 'weather-icons/font/weathericons-regular-webfont.woff'
import otf from 'weather-icons/font/WeatherIcons-Regular.otf'

import { library } from '@fortawesome/fontawesome-svg-core'
import icons from 'utils/fontAwesomeIcons'

import withNavigation from 'components/withNavigation'

import jsbridge from 'utils/jsbridge'

library.add(icons)

const GlobalStyle = createGlobalStyle`
  * {
    outline: none;
  }
  html, body, #root {
    width: 100%;
    overflow: hidden;
    font-family: 'Open Sans', sans-serif;
    color: #444;
    transform: none !important;
  }
  html {
    box-sizing: border-box;
    cursor: default;
    font-family: sans-serif;
  }

  #canvasCenter, #canvasTriangle, #canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  @font-face {
     font-family: 'Open Sans', sans-serif;
     font-weight: 300;
     src: url('/assets/fonts/Open_Sans-Light.ttf');
  }
  @font-face {
     font-family: 'Open Sans', sans-serif;
     font-weight: 400;
     src: url('/assets/fonts/Open_Sans-Regular.ttf');
  }
  @font-face {
     font-family: 'Open Sans', sans-serif;
     font-weight: 700;
     src: url('/assets/fonts/Open_Sans-Bold.ttf');
  }
  @font-face {
     font-family: 'Open Sans', sans-serif;
     font-weight: 800;
     src: url('/assets/fonts/Open_Sans-ExtraBold.ttf');
  }
  @font-face {
    font-family: 'weathericons';
    src: url(${eot});
    src: url(${woff}) format('woff'), url(${otf}) format('truetype'), url(${svg}) format('svg'), url(${ttf} format('ttf');
    font-weight: normal;
    font-style: normal;
  }
`

class App extends Component {

  componentDidMount() {
    jsbridge.call('setInternetConnection', true)
    document.withNavigation.setBackCallback(this.handleBack)
  }

  handleInternet = online => {
    jsbridge.call('setInternetConnection', online)
  }

  handleBack = () => {
    document.withNavigation.setShouldStoreFocusableTo(false)
    router.goBack()
  }

  render() {
    return (
      <MobxProvider {...stores}>
        <Router>
          <Detector onChange={this.handleInternet} render={()=>null} />
          <GlobalStyle />
          <Routes />
        </Router>
      </MobxProvider>
    )
  }
}

export default withNavigation(App)
