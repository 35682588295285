import { observable, action, computed, toJS, when } from 'mobx'
import { fromPromise } from 'mobx-utils'

import { api } from 'utils/fetcher'

class NewrowStore {

  @observable _content = null
  @computed get link() { return this._content?.value }
  @computed get content() { return this._content?.value || {} }

  @action
  getContent = contentId => {
    this._content = fromPromise(api.post().from.newrow.forContent(contentId))
  }

  @computed get url() { return this.content.url ? this.content.url + "&disable_local_storage=1&usecs=true" : this.content.url }

  @computed get isEmpty() { return this._content?.state !== 'fulfilled' && !Object.keys(this.content).length }
}

export const newrowStore = new NewrowStore()
export default newrowStore
