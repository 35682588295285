import { messagesStore, photosStore, eventsStore, contactsStore, contentStore, servicesStore, pageStore,sharedStore } from 'stores'
import { router } from 'Routes'

const jsbridge = {
  call: (method, ...args) => {
    if (!window.JSInterface) return false
    const newArgs = args.map(item => typeof item === 'object' ? JSON.stringify(item) : item)
    try {
      return window.JSInterface[method](...newArgs)
    } catch (e) {
      console.error(`JSInterface for method "${method}" with error: ${e}`)
    }
  },
  navigateTo: (page, method) => {
    router.navigateTo(page, method)
  },
  pauseYouTube: (contentYoutubeId, milisec) => {
    const timePlayed = { 
      last: milisec,
      isFinished: false
    }
    contentStore.setYouTubePlayData(contentYoutubeId, timePlayed)
  },
  finishedYouTube: (contentYoutubeId) => {
    const timePlayed = {
      last: 0,
      isFinished: true
    }
    contentStore.setYouTubePlayData(contentYoutubeId, timePlayed)
  },
  textReadFinished :()=>{
    sharedStore.setIsNowReadingState(false,true)
  },
  enableFocus :()=>{
    document.withNavigation?.setDisabled(false)
  },
  disableFocus :()=>{
    document.withNavigation?.setDisabled(true)
  },
  goBack: () => {
    document.withNavigation.pressKey('Escape')
  },
  toConsole: msg => console.log(`Msg From Native: ${msg}`),
  refreshStore: store => {
    const stores = {
      message: () => messagesStore.refreshStore(),
      photo: () => photosStore.refreshStore(),
      calendar_event: () => eventsStore.refreshStore(),
      contact: () => contactsStore.refreshStore(),
      butler_request: () => servicesStore.refreshStore(),
      tv_menu: () => pageStore.refreshStore(),
    }
    if (stores[store]) stores[store]()
  }
}

const proxy = () => {
  if (!jsbridge) return {}
  return new Proxy(jsbridge, {
    get(target, prop) {
      if (!target[prop]) return {}
      const parsed = new Proxy(target[prop], {
        apply: (func, thisArg, args) => {
          const json = JSON.parse(args)
          return func.apply(thisArg, json)
        }
      })
      return parsed
    }
  })
}
window.JSFunctions = proxy()

export default jsbridge
