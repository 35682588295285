import eventsStore    from 'stores/eventsStore'
import userStore      from 'stores/userStore'
import welcomeStore   from 'stores/welcomeStore'
import pageStore      from 'stores/pageStore'
import contactsStore  from 'stores/contactsStore'
import messagesStore  from 'stores/messagesStore'
import photosStore     from 'stores/photosStore'
import contentStore   from 'stores/contentStore'
import interestStore  from 'stores/interestStore'
import servicesStore  from 'stores/servicesStore'
import newrowStore    from 'stores/Newrow.store'
import sharedStore    from 'stores/sharedStore'
const stores = {
  messagesStore,
  eventsStore,
  userStore,
  welcomeStore,
  pageStore,
  contactsStore,
  photosStore,
  contentStore,
  interestStore,
  servicesStore,
  newrowStore,
  sharedStore
}

export {
  messagesStore,
  eventsStore,
  userStore,
  welcomeStore,
  pageStore,
  contactsStore,
  photosStore,
  contentStore,
  interestStore,
  servicesStore,
  newrowStore,
  sharedStore
}

export default stores
