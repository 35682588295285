import React from 'react'
import styled from 'styled-components'
import { format, isToday, isTomorrow, isBefore, addDays } from 'date-fns'
import { Text, theme } from 'UI'

import { DiaryEvent } from 'components/Diary'

const StyledTitle = styled(Text.H2)`
  color: white;
  margin-bottom: ${theme.interval()};
  text-align: center;
`

const Title = ({ forDate, ...props }) => {
  const getTitle = date => {
    if (isToday(date)) return 'Today'
    if (isTomorrow(date)) return 'Tomorrow'
    if (isBefore(date, addDays(new Date(), 7))) return 'This week'
    return 'Further this month'
  }
  return <StyledTitle bold>{getTitle(forDate)}</StyledTitle>
}

function DiaryDay({ day: [number, events], props, ...rest }) {
  const data = events[0].ISOdate
  return (
    <>
      {events && events.length > 0 && <Title forDate={data} />}
      {events.map((event, index) => <DiaryEvent key={`event_${event.id}_${event.recurrence_id}`} {...event} {...rest} first focusOrder={index + 1} {...props} name={`DiaryEvent_${number}_${index}`} />)}
    </>
  )
}

export default DiaryDay
