import { observable, action, computed, toJS, reaction } from 'mobx'
import { fromPromise } from 'mobx-utils'

import { updateIn, deleteFrom, withStatusFor, getFrom } from 'utils/storeHelpers'

import { api } from 'utils/fetcher'

class InterestStore {

  @observable _items = {}
  @computed get link() { return this._items?.value }
  @computed get interests() { return toJS(this._items?.value) || [] }

  @observable _currentItem = null
  @computed get currentItem() { return toJS(this._currentItem) || {} }

  @observable _saved = []
  @computed get saved() { return toJS(this._saved) }

  @action
  getInterests = () => this._items = fromPromise(api.get().from.preference.all())

  @action
  getRandom = () => {
    if (!this.interests.length) return false

    const randomIndex = Math.floor(Math.random() * this.interests.length)
    const item = this.interests[randomIndex]
    this._currentItem = item
    deleteFrom(this.link).where('id', item.id)
  }

  @action
  makeLike = likeOrDislike => {
    if (likeOrDislike === 'like') this._saved.push(this.currentItem)
    api.post().to.preference.like(this.currentItem.id, likeOrDislike === 'like' ? 'yes' : 'no')
  }

  @action
  restartGame = () => {
    this._saved = []
    this.getInterests()
  }

}

export const interestStore = new InterestStore()
export default withStatusFor(interestStore, { onPath: '/main/interests' })
