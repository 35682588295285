import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import styled, { keyframes, css } from 'styled-components'

import { theme, Text } from 'UI'

const MULTI = 120 // More - faster

const first = width => keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-${width}px);
  }
`

const second = width => keyframes`
  0% {
    transform: translateX(100%);
  }
  25% {
    transform: translateX(0%);
  }
  75% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-${width}px);
  }
`

const animation = css`
  animation: 
  ${p => first(p.width)} ${p => (p.width / MULTI) * 1000}ms linear 5000ms 1, 
  ${p => second(p.width)} ${p => (p.width / MULTI * 3) * 1000}ms linear ${p => (p.width / MULTI) * 1000 + 5000}ms infinite;
`

const ellipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
`

const MarqueeContainer = styled.div`
  overflow: hidden;
`

const MarqueeSlider = styled(Text.H2)`
  white-space: nowrap;
  ${p => !p.run && ellipsis}
  ${p => p.run && animation}
`

function Marquee({ children, run, speed, ...rest }) {
  const [ width, setWidth ] = useState(0)
  const [ needRun, setRun ] = useState(false)

  const text = useRef(null)
  const container = useRef(null)

  useEffect(() => {
    if (text.current?.offsetWidth > container.current?.offsetWidth) setRun(true)
  },[text.current, container.current])

  useEffect(() => {
    setWidth(text.current?.offsetWidth)
  }, [run])

  return (
    <MarqueeContainer {...rest} ref={container}>
      <MarqueeSlider run={run && needRun} width={width} thin>
        <span ref={text}>
          {children}
        </span>
      </MarqueeSlider>
    </MarqueeContainer>
  )
}

export default Marquee
