import reminderImage    from 'assets/reminders/reminder.png'
import anniversaryImage from 'assets/reminders/anniversary.png'
import birthdayImage    from 'assets/reminders/birthday.png'
import graduationImage  from 'assets/reminders/graduation.png'
import medicImage       from 'assets/reminders/medic.png'
import medicationImage  from 'assets/reminders/medication.png'

const getImageSrc = (type) => {
  const imagesEnum = {
    reminder: reminderImage,
    anniversary: anniversaryImage,
    birthday: birthdayImage,
    graduation: graduationImage,
    medic: medicImage,
    medication: medicationImage
  }
  return imagesEnum[type.toLowerCase()] || imagesEnum.reminder
}
export default getImageSrc
